import { NextRequest, NextResponse } from "next/server";

export interface ExperimentVariant {
    name: string;
    traffic: number;
    label?: string;
}

export interface ExperimentConfig {
    id: string;
    traffic: number;
    variants: Array<ExperimentVariant>;
}

export function getExperimentVariant(
    config: ExperimentConfig,
    randomNumberForTesting: number | null = null,
): ExperimentVariant | null {
    const randomNumber = randomNumberForTesting ?? Math.random();
    const trafficWithoutABTesting = Math.fround(1 - config.traffic);

    if (randomNumber <= trafficWithoutABTesting) {
        return null;
    }

    let sumTraffic = trafficWithoutABTesting;
    for (const variant of config.variants) {
        sumTraffic += variant.traffic * config.traffic;

        if (randomNumber <= sumTraffic) {
            return variant;
        }
    }

    return null;
}

function handleExperimentCookie(
    request: NextRequest,
    response: NextResponse,
    experimentConfig: ExperimentConfig,
): void {
    const cookieValue = request.cookies.get(experimentConfig.id);

    if (cookieValue) {
        return;
    }

    const experimentVariant = getExperimentVariant(experimentConfig);

    response.cookies.set({
        name: `uxf-experiment-${experimentConfig.id}`,
        value: experimentVariant?.name ?? "Control",
        maxAge: 60 * 60 * 24 * 3, // 3 days
    });
}

export function handleABTesting(request: NextRequest, response: NextResponse, experiments: ExperimentConfig[]) {
    experiments.forEach((experimentConfig) => handleExperimentCookie(request, response, experimentConfig));
}

export function sendABTestingEvent() {
    if (typeof document === "undefined") {
        // eslint-disable-next-line no-console
        console.error("Document is undefined. This method can be called only on client.");
        return;
    }
    if (typeof window.dataLayer === "undefined") {
        // eslint-disable-next-line no-console
        console.error("DataLayer is undefined. GTM must be implemented.");
        return;
    }

    decodeURIComponent(document.cookie)
        .split(";")
        .map((cookie) => cookie.trim().split("="))
        .map(([cookieName, cookieValue]) => ({ name: cookieName, value: cookieValue }))
        .forEach((cookie) => {
            if (cookie.name.startsWith("uxf-experiment-") && typeof window.dataLayer !== "undefined") {
                window.dataLayer.push({
                    event: "experience_impression",
                    exp_variant_string: `${cookie.name}-${cookie.value}`,
                } as any);
            }
        });
}
