import { Notification } from "@ui/components/FlashMessages/FlashMessage";
import { FlashMessages } from "@ui/components/FlashMessages/FlashMessages";
import { createRef, ReactElement } from "react";

const flashMessagesRef = createRef<FlashMessages>();

export const getFlashMessagesRef = () => {
    return flashMessagesRef;
};

function flashMessage(notification: Notification) {
    if (flashMessagesRef.current) {
        flashMessagesRef.current.open(notification);
    }
}

function openPermanentComponent(Component: ReactElement) {
    if (flashMessagesRef.current) {
        flashMessagesRef.current.openPermanent(Component);
    }
}

function closePermanentComponent() {
    if (flashMessagesRef.current) {
        flashMessagesRef.current.closePermanent();
    }
}

const successMessage = (message: string, body?: string) => {
    return flashMessage({ title: message, body, variant: "success" });
};

const warningMessage = (message: string, body?: string) => {
    return flashMessage({ title: message, body, variant: "warning" });
};

const errorMessage = (message: string, body?: string) => {
    return flashMessage({ title: message, body, variant: "error" });
};

export const FlashMessagesService = {
    flashMessage,
    successMessage,
    warningMessage,
    errorMessage,
    openPermanentComponent,
    closePermanentComponent,
};
