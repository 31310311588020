import { config } from "@config/config";
import { useProfileMeLazyQuery } from "@gql-schema/ProfileQueries";
import { Cookie } from "@uxf/core/cookie";
import { useEffect, useState } from "react";

const useHandleLoggedProfile = () => {
    const [lastLoginCookie, setLastLoginCookie] = useState<string | null>(null);
    const [reloadProfile] = useProfileMeLazyQuery({
        fetchPolicy: "network-only",
        nextFetchPolicy: "network-only",
        ssr: false, // TODO Bee -> proc je to sakra potreba? jinak to blokuje SSR
    });

    useEffect(() => {
        setLastLoginCookie(Cookie.create().get(config.LAST_LOGIN_COOKIE) || null);

        const interval = setInterval(() => {
            setLastLoginCookie(Cookie.create().get(config.LAST_LOGIN_COOKIE) || null);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        reloadProfile();
    }, [lastLoginCookie, reloadProfile]);
};

/**
 * Aktualizuje logged profile pokud se změní 'Last-Login' cookie
 * optimalizace aby hook useLoggedProfile nemusel mít fetchPolicy === cache-and-network
 */
export const LoggedProfileHandler: React.FC = () => {
    useHandleLoggedProfile();
    return null;
};
