export const COLORS = {
    yellow: {
        lighter: "#fed42a",
        light: "#fed01a",
        default: "#fecd09",
        dark: "#f9c701",
        darker: "#eabb01",
        secondary: "#fff0b5",
        secondaryLight: "#fff8df",
    },
    green: {
        lighter: "#057066",
        light: "#056159",
        default: "#04534c",
        dark: "#03443e",
        darker: "#033631",
        secondaryLight: "#f2f6f6",
        secondary: "#d9e5e4",
        secondaryDark: "#cfdddc",
    },
    blue: "#4357ad",
    blog: {
        gold: "#ffe375",
        linen: "#f8ebe3",
        pink: "#f9d8d9",
        turquoise: "#cfdddc",
        violet: "#d3cfdd",
        yellow: "#f8f8d2",
    },
    white: "#fff",
    neutral: {
        50: "#f6f6f6",
        100: "#e5e5e5",
        200: "#ccc",
        300: "#b2b2b2",
        400: "#999",
        500: "#808080",
        600: "#666",
        700: "#4d4d4d",
        800: "#333",
        900: "#1a1a1a",
    },
    black: "#000",
    error: {
        lighter: "#fce6e6",
        light: "#ff6262",
        default: "#fb4e4e",
        dark: "#e41212",
    },
    success: {
        light: "#adf1c8",
        default: "#1aab54",
        dark: "#189e4e",
    },
    warning: {
        light: "#ffbc1f",
        default: "#f6a609",
        dark: "#e89806",
    },
    social: {
        facebookBlue: "#1877f2",
        facebookBlueOld: "#4357ad",
        twitterBlue: "#1ea1f1",
    },
};

export type ColorsType = typeof COLORS;
