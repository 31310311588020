import { UxfPageContext } from "@app-routes";
import type {
    ProfileAdminMeQuery,
    ProfileAdminMeQueryVariables,
    SaveMarketingProfileMutation,
    SaveMarketingProfileMutationVariables,
} from "@gql-schema";
import { SaveMarketingProfileDocument } from "@gql-schema/MarketingMutations";
import { ProfileAdminMeDocument } from "@gql-schema/ProfileQueries";
import { ApolloClient } from "@shared/lib/Apollo/types";
import { isNotNil } from "@uxf/core/utils/is-not-nil";
import { prepareMarketingProfileInput } from "./prepareMarketingProfileInput";
import { SaveMarketingProfileData } from "./types";

export const GCLID_NAME = "g_c_fan";

export async function saveMarketingProfile(
    rawData: SaveMarketingProfileData,
    apollo: ApolloClient,
    ctx?: UxfPageContext<any>,
) {
    let isBackofficeProfile: boolean;

    try {
        const adminMe = await apollo.query<ProfileAdminMeQuery, ProfileAdminMeQueryVariables>({
            query: ProfileAdminMeDocument,
        });

        isBackofficeProfile = isNotNil(adminMe.data);
    } catch (e) {
        isBackofficeProfile = false;
    }

    await apollo.mutate<SaveMarketingProfileMutation, SaveMarketingProfileMutationVariables>({
        mutation: SaveMarketingProfileDocument,
        variables: { data: prepareMarketingProfileInput(rawData, ctx, isBackofficeProfile) },
    });
}
