export function normalizePhoneNumber(phoneNumber: string, countryCode: string) {
    let cleaned = phoneNumber.replace(/\D/g, "");

    if (cleaned.startsWith("0")) {
        cleaned = cleaned.replace(/^0+/, "");
    }

    if (!cleaned.startsWith(countryCode)) {
        cleaned = `${countryCode}${cleaned}`;
    }

    return `+${cleaned.padStart(12, "0")}`;
}
