/* eslint-disable */
/* tslint:disable */
import * as Types from '../generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const SaveMarketingProfileDocument = gql`
    mutation saveMarketingProfile($data: MarketingMarketingProfileSaveInput!) {
  saveMarketingProfile(data: $data)
}
    `;
export type SaveMarketingProfileMutationFn = Apollo.MutationFunction<Types.SaveMarketingProfileMutation, Types.SaveMarketingProfileMutationVariables>;

/**
 * __useSaveMarketingProfileMutation__
 *
 * To run a mutation, you first call `useSaveMarketingProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveMarketingProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveMarketingProfileMutation, { data, loading, error }] = useSaveMarketingProfileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSaveMarketingProfileMutation(baseOptions?: Apollo.MutationHookOptions<Types.SaveMarketingProfileMutation, Types.SaveMarketingProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SaveMarketingProfileMutation, Types.SaveMarketingProfileMutationVariables>(SaveMarketingProfileDocument, options);
      }
export type SaveMarketingProfileMutationHookResult = ReturnType<typeof useSaveMarketingProfileMutation>;
export type SaveMarketingProfileMutationResult = Apollo.MutationResult<Types.SaveMarketingProfileMutation>;
export type SaveMarketingProfileMutationOptions = Apollo.BaseMutationOptions<Types.SaveMarketingProfileMutation, Types.SaveMarketingProfileMutationVariables>;