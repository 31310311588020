import { ButtonProps } from "@ui/components/Button/Button";
import { COLORS } from "@ui/theme/colors";
import { useClickableProps } from "@uxf/core/hooks/useClickableProps";
import { darken } from "color2k";
import { CSSProperties, ReactNode, useEffect } from "react";
import styles from "./FlashMessage.module.css";

export interface Notification {
    autoDismiss?: boolean;
    body?: ReactNode;
    buttonProps?: Partial<ButtonProps>;
    dismissTimeout?: number;
    id?: number;
    onClick?: () => void;
    title: ReactNode;
    variant?: "error" | "warning" | "success" | "default";
}

export interface FlashMessageProps {
    notification: Notification;
    onClose: () => void;
}

const VARIANTS = {
    error: {
        bgColor: COLORS.error.default,
        bgColorHover: darken(COLORS.error.default, 0.1),
        color: COLORS.white,
    },
    warning: {
        bgColor: COLORS.warning.default,
        bgColorHover: darken(COLORS.warning.default, 0.1),
        color: COLORS.black,
    },
    success: {
        bgColor: COLORS.success.default,
        bgColorHover: darken(COLORS.success.default, 0.1),
        color: COLORS.white,
    },
    default: {
        bgColor: COLORS.white,
        bgColorHover: darken(COLORS.white, 0.1),
        color: COLORS.black,
    },
};

function getVariant(variant: keyof typeof VARIANTS) {
    return {
        "--flash-message__bg-color": VARIANTS[variant].bgColor,
        "--flash-message__bg-color--hover": VARIANTS[variant].bgColorHover,
        "--flash-message__color": VARIANTS[variant].color,
    } as CSSProperties;
}

export function FlashMessage(props: FlashMessageProps) {
    useEffect(() => {
        let timer: number;
        if (props.notification.autoDismiss) {
            timer = window.setTimeout(props.onClose, props.notification.dismissTimeout);
        }
        return () => clearTimeout(timer);
    }, [props.notification.autoDismiss, props.notification.dismissTimeout, props.onClose]);

    const clickableProps = useClickableProps({
        className: styles.flashMessage,
        onClick: props.onClose,
        role: "listitem",
        style: getVariant(props.notification.variant || "default"),
    });

    return (
        <div {...clickableProps}>
            <div className="grow-1 px-5 py-6">
                <p className="text-20 font-bold md:text-22 lg:text-24">{props.notification.title}</p>
                {!!props.notification.body && <p className="ui-typo-small mt-5">{props.notification.body}</p>}
            </div>
        </div>
    );
}
