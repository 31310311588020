var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3bSbqIWAzV9x6fK12XZRE"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const STAGE = process.env.STAGE || process.env.NEXT_PUBLIC_STAGE;

Sentry.init({
    dsn: SENTRY_DSN,
    environment: STAGE,
    tracesSampleRate: 0.1,
    profilesSampleRate: 1.0,
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
        Sentry.browserTracingIntegration({
            enableInp: true,
        }),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
            networkDetailAllowUrls: [
                /\/graphql/i,
            ],
        }),
        Sentry.browserProfilingIntegration(),
    ],
    beforeSend(event, hint) {
        const userAgent = navigator.userAgent;

        // Type guard to check if hint.originalException has a message property
        const isErrorWithMessage = (exception: any): exception is Error => {
            return exception && typeof exception.message === 'string';
        };

        const errorMessage = isErrorWithMessage(hint?.originalException) ? hint.originalException.message : '';

        // If the error is related to hydration and the user agent is HeadlessChrome
        if (
            /HeadlessChrome/.test(userAgent)
            && (
                errorMessage.includes("There was an error while hydrating but React was able to recover by instead client rendering the entire root.")
                || errorMessage.includes("Hydration failed because the server rendered HTML didn't match the client.")
            )
        ) {
            return null;
        }

        return event;
    },
    // @see https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/filtering/#decluttering-sentry
    ignoreErrors: [
        // Random plugins/extensions
        "top.GLOBALS",
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        "originalCreateNotification",
        "canvas.contentDocument",
        "atomicFindClose",
        // Facebook borked
        "fb_xd_fragment",
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        "bmi_SafeAddOnload",
        "EBCallBackMessageReceived",
    ],
    denyUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/(all|fbevents)\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Firefox extensions
        /^resource:\/\//i,
        // Ignore Google flakiness
        /\/(gtm|ga|analytics)\.js/i,
        // Ignore Seznam errors
        /\/(ssp|dot-small)\.js/i,
        /c\.seznam\.cz\/js\/rc\.js/i,
    ],
});
