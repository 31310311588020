/* eslint-disable */
/* tslint:disable */
import * as Types from '../generated';

import { gql } from '@apollo/client';
export const CampaignFragmentDoc = gql`
    fragment Campaign on Campaign {
  id
  uuid
  active
  name
}
    `;