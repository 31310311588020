import { UxfPageContext } from "@app-routes";
import { MarketingMarketingProfileSaveInput } from "@gql-schema";
import { AnalyticsService } from "@shared/services/AnalyticsService";
import { Cookie } from "@uxf/core/cookie";
import { SaveMarketingProfileData } from "./types";

export function prepareMarketingProfileInput(
    rawData: SaveMarketingProfileData,
    ctx?: UxfPageContext<any> | null,
    isBackofficeProfile?: boolean,
): MarketingMarketingProfileSaveInput {
    const cookie = new Cookie(ctx);

    const analyticsSessionId = cookie.get("Analytics-Session-Id");
    let { utmCampaign, utmSource, utmMedium } = AnalyticsService.getUtmParameters();
    const landingUrl = AnalyticsService.getLandingUrl();

    if (isBackofficeProfile) {
        utmCampaign = "sales";
        utmSource = "web";
        utmMedium = null;
    }

    return {
        utmCampaign,
        utmSource,
        utmMedium,
        landingUrl,
        ...rawData,
        eventValueText2: analyticsSessionId,
        eventSource: "WEB",
    };
}
