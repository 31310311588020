/* eslint-disable */
/* tslint:disable */
import * as Types from '../generated';

import { gql } from '@apollo/client';
import { MyProfileFragmentDoc, AdminProfileFragmentDoc } from './ProfileFragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const ProfileMeDocument = gql`
    query ProfileMe {
  profileMe {
    ...MyProfile
  }
}
    ${MyProfileFragmentDoc}`;

/**
 * __useProfileMeQuery__
 *
 * To run a query within a React component, call `useProfileMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileMeQuery(baseOptions?: Apollo.QueryHookOptions<Types.ProfileMeQuery, Types.ProfileMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProfileMeQuery, Types.ProfileMeQueryVariables>(ProfileMeDocument, options);
      }
export function useProfileMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProfileMeQuery, Types.ProfileMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProfileMeQuery, Types.ProfileMeQueryVariables>(ProfileMeDocument, options);
        }
export type ProfileMeQueryHookResult = ReturnType<typeof useProfileMeQuery>;
export type ProfileMeLazyQueryHookResult = ReturnType<typeof useProfileMeLazyQuery>;
export type ProfileMeQueryResult = Apollo.QueryResult<Types.ProfileMeQuery, Types.ProfileMeQueryVariables>;
export const ProfileAdminMeDocument = gql`
    query ProfileAdminMe {
  profileAdminMe {
    ...AdminProfile
  }
}
    ${AdminProfileFragmentDoc}`;

/**
 * __useProfileAdminMeQuery__
 *
 * To run a query within a React component, call `useProfileAdminMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileAdminMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileAdminMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileAdminMeQuery(baseOptions?: Apollo.QueryHookOptions<Types.ProfileAdminMeQuery, Types.ProfileAdminMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProfileAdminMeQuery, Types.ProfileAdminMeQueryVariables>(ProfileAdminMeDocument, options);
      }
export function useProfileAdminMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProfileAdminMeQuery, Types.ProfileAdminMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProfileAdminMeQuery, Types.ProfileAdminMeQueryVariables>(ProfileAdminMeDocument, options);
        }
export type ProfileAdminMeQueryHookResult = ReturnType<typeof useProfileAdminMeQuery>;
export type ProfileAdminMeLazyQueryHookResult = ReturnType<typeof useProfileAdminMeLazyQuery>;
export type ProfileAdminMeQueryResult = Apollo.QueryResult<Types.ProfileAdminMeQuery, Types.ProfileAdminMeQueryVariables>;
export const ProfileHasPhoneDocument = gql`
    query ProfileHasPhone($email: String!) {
  hasPhone(email: $email) {
    hasPhone
  }
}
    `;

/**
 * __useProfileHasPhoneQuery__
 *
 * To run a query within a React component, call `useProfileHasPhoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileHasPhoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileHasPhoneQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useProfileHasPhoneQuery(baseOptions: Apollo.QueryHookOptions<Types.ProfileHasPhoneQuery, Types.ProfileHasPhoneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProfileHasPhoneQuery, Types.ProfileHasPhoneQueryVariables>(ProfileHasPhoneDocument, options);
      }
export function useProfileHasPhoneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProfileHasPhoneQuery, Types.ProfileHasPhoneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProfileHasPhoneQuery, Types.ProfileHasPhoneQueryVariables>(ProfileHasPhoneDocument, options);
        }
export type ProfileHasPhoneQueryHookResult = ReturnType<typeof useProfileHasPhoneQuery>;
export type ProfileHasPhoneLazyQueryHookResult = ReturnType<typeof useProfileHasPhoneLazyQuery>;
export type ProfileHasPhoneQueryResult = Apollo.QueryResult<Types.ProfileHasPhoneQuery, Types.ProfileHasPhoneQueryVariables>;