import { config } from "@config/config";
import { useSaveMarketingProfileMutation } from "@gql-schema/MarketingMutations";
import { useBackofficeProfile } from "@shared/hooks/useBackofficeProfile";
import { isNotNil } from "@uxf/core/utils/is-not-nil";
import { useCallback } from "react";
import { prepareMarketingProfileInput } from "./prepareMarketingProfileInput";
import { SaveMarketingProfileData } from "./types";

export const useSaveMarketingProfile = () => {
    const backofficeProfile = useBackofficeProfile();
    const [mutation] = useSaveMarketingProfileMutation();

    return useCallback(
        (rawData: SaveMarketingProfileData) => {
            const data = prepareMarketingProfileInput(rawData, null, isNotNil(backofficeProfile));
            mutation({ variables: { data } });
            if (config.STAGE !== "prod") {
                // eslint-disable-next-line no-console
                console.log("📘 DEBUG MKTG PROFILE DATA: ", data);
            }
        },
        [backofficeProfile, mutation],
    );
};
