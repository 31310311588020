import { rem } from "@uxf/styles/utils/rem";
import { PureComponent, ReactElement } from "react";
import { FlashMessage, Notification } from "./FlashMessage";
import styles from "./FlashMessage.module.css";

interface FlashMessagesState {
    PermanentComponent?: ReactElement | null;
    notifications: (Omit<Notification, "id"> & Required<Pick<Notification, "id">>)[];
    windowInnerHeight: string;
}

export class FlashMessages extends PureComponent<Record<string, any>, FlashMessagesState> {
    constructor(props: Record<string, any>, context: any) {
        super(props, context);
        this.state = {
            notifications: [],
            windowInnerHeight: "100vh",
        };
    }

    public componentDidMount(): void {
        this.handleWindowInnerHeight();
        window.addEventListener("resize", this.handleWindowInnerHeight);
    }

    public componentWillUnmount(): void {
        window.removeEventListener("resize", this.handleWindowInnerHeight);
    }

    private handleWindowInnerHeight = () => {
        /* TODO add some debounce method */
        this.setState({ windowInnerHeight: rem(window.innerHeight) });
    };

    public open = (notification: Notification) => {
        this.setState((state) => ({
            notifications: [
                ...state.notifications,
                {
                    ...notification,
                    variant: notification.variant || "default",
                    id: notification.id || Math.round(Math.random() * 1000),
                },
            ],
        }));
    };

    public openPermanent = (PermanentComponent: ReactElement) => {
        this.setState({
            PermanentComponent,
        });
    };

    public closePermanent = () => {
        this.setState({
            PermanentComponent: null,
        });
    };

    public close = (notification: Notification) => {
        this.setState((state) => ({
            notifications: state.notifications.filter((n) => n !== notification),
        }));
    };

    public render() {
        const { notifications, PermanentComponent, windowInnerHeight } = this.state;
        return (
            <div className={styles.wrapper} role="list" style={{ height: windowInnerHeight }}>
                {notifications.map((notification) => (
                    <FlashMessage
                        key={notification.id.toString()}
                        notification={notification}
                        onClose={() => this.close(notification)}
                    />
                ))}
                {PermanentComponent}
            </div>
        );
    }
}
